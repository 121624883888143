@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom global styles */
body, #root {
  min-height: 100vh;
  overflow-x: hidden;
}

body {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #dbeafe;
  --tw-gradient-to: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  color: #111827;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  font-family: 'Times New Roman', Times, serif;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 800;
  color: #1e3a8a;
  font-family: 'Times New Roman', Times, serif;
}

p, li, span {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #374151;
  font-family: 'Times New Roman', Times, serif;
}

@media (min-width: 640px) {
  p, li, span {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

a {
  color: #2563eb;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  font-family: 'Times New Roman', Times, serif;
}

a:hover {
  color: #60a5fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
